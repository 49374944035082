import { Route, Switch } from "react-router-dom";
import Pagina404 from './pages/Pagina404';
import MenuBar from './components/Menu';
import Login from './pages/Login';
import MinhaTransicao from "./pages/MinhaTransicao";
import Acoes from "./pages/Acoes";
import Programa from "./pages/Programa";
import DadosPessoais from "./pages/DadosPessoais";
import PPC from "./pages/PPC";
import MenuComponent from "./pages/Menu";
import SecaoComponent from "./pages/Secao";
import ConteudoComponent from "./pages/Conteudo";


function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <MenuBar>
        <Route exact path="/" component={MinhaTransicao} />
        <Route exact path="/minha-transicao" component={MinhaTransicao} />
        <Route exact path="/minha-transicao/acoes" component={Acoes} />
        <Route exact path="/minha-transicao/programa" component={Programa} />
        <Route exact path="/minha-transicao/dados-pessoais" component={DadosPessoais} />
        <Route exact path="/minha-transicao/programa/ppc" component={PPC} />
        
        {/*  Menus normal  */}
        <Route exact path="/menus/:id" component={MenuComponent} />
        <Route exact path="/menus/:menuId/secoes/:id" component={SecaoComponent} />
        <Route exact path="/menus/:menuId/secoes/:secaoId/conteudos/:id" component={ConteudoComponent} />

        {/*  Menus com submenu dentro  */}
        <Route exact path="/menus/:menuIdParent/menus/:id" component={MenuComponent} />
        <Route exact path="/menus/:menuIdParent/menus/:menuId/secoes/:id" component={SecaoComponent} />
        <Route exact path="/menus/:menuIdParent/menus/:menuId/secoes/:secaoId/conteudos/:id" component={ConteudoComponent} />
        <Route exact path="/conteudos/:id" component={ConteudoComponent} />

      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
